<template>
  <div id="share-invite-url-popup">
    <v-textarea
      title="邀請資訊"
      class="mb-4"
      outlined
      dense
      readonly
      rows="10"
      :value="allShareText"
    ></v-textarea>

    <v-btn
      @click="copy"
      color="green"
      class="white--text"
    >
      複製邀請資訊
    </v-btn>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    popupConfig: Object,
  },
  methods: {
    async copy() {
      const container = $('#share-invite-url-popup')[0]
      try {
        await this.$copyText(this.allShareText, container)
        console.warn('this.shareText', this.allShareText)
      } catch (error) {
        console.warn(error)
      } finally {
        this.$snotify.success(this.targetUrl, this.$t('copy.successfully'))
      }
    },
  },
  computed: {
    allShareText() {
      return `${this.shareTitle}\n${this.shareText}`
    },
    shareTitle() {
      return this.popupConfig.shareTitle
    },
    shareText() {
      return this.popupConfig.shareText
    },
    inviteUrl() {
      return this.popupConfig.inviteUrl
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>